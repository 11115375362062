import {
  isRequired as _isRequired,
  isRequiredIf as _isRequiredIf,
  isNumeric as _isNumeric,
  isGreaterThanOrEqual as _isGreaterThanOrEqual,
} from 'simple-object-validation';

import container from '../container';

const nameTransformer = (name) => container.i18n.t(name);

export const isRequired = _isRequired({
  messageCreator: (param, name) => container.i18n.t('{{name}} is required', { name }),
  nameTransformer,
});

export const isRequiredIf = _isRequiredIf({
  messageCreator: (param, name) => container.i18n.t('{{name}} is required', { name }),
  nameTransformer,
});

export const isNumeric = _isNumeric({
  messageCreator: (param, name) => container.i18n.t('{{name}} must be numeric', { name }),
  nameTransformer,
});

export const isGreaterThanOrEqual = _isGreaterThanOrEqual({
  messageCreator: (param, name) =>
    container.i18n.t('{{name}} must be greater than or equal to {{param}}', { name, param }),
  nameTransformer,
});

export const validateIf = (conditionFn, validator) => (values) => {
  if (!conditionFn(values)) {
    return false;
  }

  return validator(values);
};

export { assemble } from 'simple-object-validation';
export { chain } from 'simple-object-validation';
